import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics';
import './assets/scss/app.scss'

Vue.use(VueMeta)
Vue.use(VueAnalytics, {
  id: 'G-36PYDRH681',
  router
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
