<template>
  <div class="home">

    <displaybox
        :headline="'Korttidsutleie gjort enkelt'"
        :text="'La noen andre leie ut din bolig på Airbnb mens du er bortreist. Ta kontakt for en uforpliktende prat'"
        :path="'/explanation'"
    />
    <displaybox
        :headline="'Eier du ikke egen bolig?'"
        :text="'Ta kontakt likevel og vi ser på løsninger. Det er synd å betale leie for en ubrukt leilighet.'"
        :path="'/contact'"
    />
    <displaybox
        :headline="'Ønsker du stille som medvert?'"
        :text="'Send oss en melding og skriv noen ord om deg selv.'"
        :path="'/cohosts'"
    />
  </div>
</template>

<script>
// @ is an alias to /src

import Displaybox from "@/components/Displaybox";

export default {
  name: 'Home',
  components: {Displaybox}
}
</script>
<style>
</style>