<template>
  <div id="app">
    <navbar></navbar>
    <div id="page">
      <img class="logo" alt="Medvert.no" src="./assets/images/logo.png">
      <h2 class="slogan">- Inntekt mens du reiser</h2><br>
      <router-view/>
    </div>
    <footer>
      <footer-component/>
      <cookie-law buttonText="Det er greit" message="Denne siden bruker cookies for å gi deg den beste opplevelsen." theme="blood-orange"></cookie-law>
    </footer>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import Navbar from "@/components/Navbar";
import FooterComponent from "@/components/FooterComponent";
export default {
  name: 'App',
  components: {FooterComponent, Navbar, CookieLaw },
  metaInfo() {
    return {
      title: "Medvert.no - Inntekt mens du reiser",
      meta: [
        { name: 'description', content:  'Nettsiden som hjelper deg å leie ut din bolig når du selv er bortreist'},
        { property: 'og:title', content: "Medvert.no - Inntekt mens du reiser"},
        { property: 'og:site_name', content: 'Medvert.no'},
        {property: 'og:type', content: 'website'},
        {property: 'og:url',content: 'http://medvert.no'},
        {property: 'keywords',content: 'korttidsutleie, airbnb, utleiehjelp, fremlei'},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  }
}
</script>

<style>

</style>
