<template>
  <div class="navbar">
    <span id="icon-span" @click="goHome"><img class="icon" src="../assets/images/Icon.png"></span>
    <div id="nav-wide">
      <a>
        <router-link to="/">Hjem</router-link>
      </a>
      <a>
        <router-link to="/explanation">Hvordan virker det?</router-link>
      </a>
      <a>
        <router-link to="/cohosts">Våre medverter</router-link>
      </a>
      <a>
        <router-link to="/about">Om oss</router-link>
      </a>
      <a>
        <router-link to="/faq">Ofte stilte spørsmål</router-link>
      </a>
      <a>
        <router-link to="/contact">Kontakt oss</router-link>
      </a>
    </div>
    <div id="nav-narrow">
      <div class="dropdown">
        <img class="menu-icon" src="../assets/icons/menu-white.svg" alt="Menu">
        <div class="dropdown-content">
          <span @click="goHome">Hjem</span><br/><br/><br/>
          <span @click="goExplanation">Hvordan virker det?</span><br/><br/><br/>
          <span @click="goCohosts">Våre medverter</span><br/><br/><br/>
          <span @click="goAbout">Om oss</span><br/><br/><br/>
          <span @click="goContact">Kontakt oss</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Navbar",
  methods: {
    goHome() {
      router.push("/")
      window.scrollTo(0,0)
    },
    goExplanation() {
      router.push("/explanation")
      window.scrollTo(0,0)
    },
    goCohosts() {
      router.push("/cohosts")
      window.scrollTo(0,0)
    },
    goAbout() {
      router.push("/about")
      window.scrollTo(0,0)
    },
    goContact() {
      router.push("/contact")
      window.scrollTo(0,0)
    },
  }
}
</script>

<style scoped>


#icon-span {
  margin-top: 6px;
  padding: 0;
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  cursor: pointer;
}

.icon {
  height: 60px
}



.dropdown {
  position: relative;
  display: inline-block;
}

.menu-icon{
  margin-right: 5px;
  margin-top: 5px;
  height: 65px;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;

  background-color: rgba(0, 0, 0, 0.9);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}
.dropdown-content span{
  padding: 2px;
  text-align: right;
  float: right;
  width: 200px;
  font-size: 20px;
  cursor: pointer;
}
.dropdown-content span:hover{
  background: #454545;
  border-radius: 5px;
}


.dropdown:hover .dropdown-content{
  display: block;
}

</style>