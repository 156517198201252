<template>
  <div class="displaybox" @click="onClick">
    <div class="headline">
      <h2>{{ headline }}</h2>
    </div>
    <div class="text">
      {{ text }}
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Displaybox",
  props: {
    headline: String,
    text: String,
    path: String,
  },
  methods:{
    onClick(){
      router.push(this.path)
    }
  }
}
</script>

<style scoped>

</style>