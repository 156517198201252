<template>
  <div id="footer-component" class="footer-component">
    <span>© {{ new Date().getFullYear() }}</span>
    <a @click="goToFacebook"><img id="facebook-icon" src="../assets/icons/facebook-white.svg" alt="Lik oss på facebook"></a>
    <span @click="openSitemap">Sitemap</span>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    goToFacebook() {
      window.open("http://facebook.com/Medvert.no", '_blank');
    },
    openSitemap(){
      window.open("http://medvert.no/sitemap.xml")

    }
  }
}
</script>

<style scoped>

</style>